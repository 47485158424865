/**
 * External Dependencies
 */

import 'jquery';

import { createPopper } from '@popperjs/core';
import { Util, Collapse, Tooltip, OffCanvas } from 'bootstrap';

import Swiper, { Navigation, Lazy, Autoplay } from 'swiper';

import SimpleLightbox from 'simplelightbox';

$(document).ready(() => {
  // console.log('Hello world');

	var supportsTouch = 'ontouchstart' in window ||
	 window.DocumentTouch && document instanceof window.DocumentTouch ||
	 navigator.maxTouchPoints > 0 ||
	 window.navigator.msMaxTouchPoints > 0;
	 
	if(supportsTouch){
		$('.menu-item-has-children > a').on('click', function( event ){
			event.preventDefault();
			$(this).parents('ul').find('.menu-item.open').removeClass('open');
			$(this).parents('.menu-item').addClass('open');
		});
	}

	

	$('a[href*="#"]', '#content')
		// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.click(function(event) {
		// On-page links
			// Figure out element to scroll to
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top,
					}, 1000, function() {
						// Callback after animation
						// Must change focus!
						var $target = $(target);
						$target.focus();
						if ($target.is(':focus')) { // Checking if the target was focused
							return false;
						} else {
							$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
							$target.focus(); // Set focus again
						}

					});
				}
	});

	var lazyEmbeds = [].slice.call(document.querySelectorAll('video.lazy, iframe.lazy'));

	if ('IntersectionObserver' in window) {
		var lazyEmbedObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(embed) {
				if (embed.isIntersecting) {
					if (typeof embed.target.tagName === 'string' && embed.target.tagName === 'IFRAME') {
						embed.target.src = embed.target.dataset.src;
					} else {
						for (var source in embed.target.children) {
							var embedSource = embed.target.children[source];
							if (typeof embedSource.tagName === 'string' && embedSource.tagName === 'SOURCE') {
								embedSource.src = embedSource.dataset.src;
							}
						}

						embed.target.load();
					}

					embed.target.classList.remove('lazy');
					lazyEmbedObserver.unobserve(embed.target);
				}
			});
		});

		lazyEmbeds.forEach(function(lazyEmbed) {
			lazyEmbedObserver.observe(lazyEmbed);
		});
	}

	var lazyAnimations = [].slice.call(document.querySelectorAll('.animate-intro'));

	if ('IntersectionObserver' in window) {
		var lazyAnimationObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(element) {
				if (element.isIntersecting) {
					element.target.classList.add('animate');
					element.target.classList.remove('animate-intro');
					lazyAnimationObserver.unobserve(element.target);
				} 
			});
		}, {
			root: null,
			rootMargin: '0px',
			threshold: [0,1],
		});

		lazyAnimations.forEach(function(lazyAnimation) {
			lazyAnimationObserver.observe(lazyAnimation);
		});
	}

	if($('.wp-block-gallery a', '#main').length){
		const gallery = new SimpleLightbox('#main .wp-block-gallery a', {
			navText: [
				'<button rel="button" class="btn btn-dark px-3 position-fixed top-50 start-0 translate-middle-y" aria-label="next" tabindex="-1"><i class="fal fa-angle-left" style="width: 1rem;"></i></button>',
				'<button rel="button" class="btn btn-dark px-3 position-fixed top-50 end-0 translate-middle-y" aria-label="previous" tabindex="-1"><i class="fal fa-angle-right" style="width: 1rem;"></i></button>',
				],
			closeText: '&times;',
		});
		gallery.on('shown.simplelightbox', function () {
			dom.i2svg();
		});
	}

	//Slider
	function initSwiper(carousel){
		var $carousel = $(carousel);
		var carouselOptions = {
			slidesPerView : $carousel.data('items') ? $carousel.data('items') 		: 1,
			spaceBetween: 0,
			cssMode: true,
			simulateTouch: true,
			navigation: {
				nextEl: $carousel.parent('.swiper').find('.swiper-button-next')[0],
				prevEl: $carousel.parent('.swiper').find('.swiper-button-prev')[0],
			},
			breakpoints: {
				414: {},
				576: {},
				768: {},
				992: {},
				1320: {},
			},
		};

		if( $carousel.data('xs-items') ){ 	carouselOptions.breakpoints[414].slidesPerView = parseInt($carousel.data('xs-items')); }
		if( $carousel.data('xs-center') ){ carouselOptions.breakpoints[414].centeredSlides = $carousel.data('xs-center'); }

		if( $carousel.data('sm-items') ){ 	carouselOptions.breakpoints[576].slidesPerView = parseInt($carousel.data('sm-items')); }
		if( $carousel.data('sm-center') ){ carouselOptions.breakpoints[576].centeredSlides = $carousel.data('sm-center'); }

		if( $carousel.data('md-items') ){ 	carouselOptions.breakpoints[768].slidesPerView = parseInt($carousel.data('md-items')); }
		if( $carousel.data('md-center') ){ carouselOptions.breakpoints[768].centeredSlides = $carousel.data('md-center'); }

		if( $carousel.data('lg-items') ){ 	carouselOptions.breakpoints[992].slidesPerView = parseInt($carousel.data('lg-items')); }
		if( $carousel.data('lg-center') ){ carouselOptions.breakpoints[992].centeredSlides = $carousel.data('lg-center'); }

		if( $carousel.data('xl-items') ){ 	carouselOptions.breakpoints[1320].slidesPerView = parseInt($carousel.data('xl-items')); }
		if( $carousel.data('xl-center') ){ carouselOptions.breakpoints[1320].centeredSlides = $carousel.data('xl-center'); }

		if( $carousel.data('lazy') ){ carouselOptions.preloadImages = true; carouselOptions.lazy = true;  }

		if( $carousel.data('direction') ){ carouselOptions.direction = $carousel.data('direction');}

		/*
		if( $carousel.data('thumbs') ){ 
			var $thumbCarousel = $( $carousel.data('thumbs') );
			if($thumbCarousel.length){
				var	thumbCarousel = $thumbCarousel[0];
				$thumbCarousel.addClass('swiper-container');
				carouselOptions.thumbs = { swiper: initSwiper(thumbCarousel) };
			}  
		}
		*/

		var swiper =  new Swiper(carousel, carouselOptions);

		if( $carousel.data('tabs') ){ 
			$carousel.siblings('.nav-tabs').find('.nav-link[data-target]').on('click', function(){
				swiper.slideTo($(this).data('target'));
			});

			swiper.on('slideChange', function () {
				$carousel.siblings('.nav-tabs').find('.nav-link').removeClass('active').attr('aria-selected', 'false');
				$carousel.siblings('.nav-tabs').find('[data-target="' + swiper.activeIndex + '"]').addClass('active').attr('aria-selected', 'true');
			});
		}
		
		return swiper;
	}


	function initSwipers(){
		Swiper.use([Navigation, Lazy]);
		$('.swiper-container', '#main').each(function(){
			var carousel = this, $carousel = $(this);
			if( $carousel.find('> .swiper-wrapper').children().length > 1 && (!$carousel.hasClass('no-mobile') || !isMobile)){
				const swiper = new initSwiper(this);
			} else {
				$carousel.parents('.swiper').find('.swiper-button-next, .swiper-button-prev').hide();
			}
		});
	}

	if($('.swiper-container', '#main').length){
		if (document.readyState === 'complete') {
			initSwipers();
		} else {
			window.addEventListener('load', (event) => {
				initSwipers();
			}, {passive: true, once: true});
		}
	}


	if($('form.wpcf7-form', '#main, #footer').length){

		$('input[name="name"]', 'form.wpcf7-form').attr('name', 'your-name');

		$('form.wpcf7-form', '#main, #footer').on('submit', function(){
			var $form = $(this);
			$form.find('.is-invalid').removeClass('is-invalid');
			$form.addClass('sending');
		});

		document.addEventListener( 'wpcf7submit', function( event ) {

			var $form = $(event.target);

			$form.removeClass('sending');

			$('.screen-reader-response').addClass('sr-only');
			
			$('.wpcf7-not-valid', $form).addClass('is-invalid');
			$('.wpcf7-not-valid-tip', $form).addClass('invalid-feedback');

			$('.wpcf7-response-output', $form).attr('class', 'wpcf7-response-output').html('').append(event.detail.apiResponse.message);

			if(event.detail.apiResponse.status === 'mail_sent'){

				$('.wpcf7-response-output', $form).detach().insertAfter($form).addClass('alert alert-success').show();

				$form.slideUp( 'slow');

			} else if(event.detail.apiResponse.status === 'validation_failed'){

				$form.find('.invalid-feedback').remove();
				$.each(event.detail.apiResponse.invalid_fields, function(k,v){
					$form.find('#'+v.idref).addClass('is-invalid').after('<div class="invalid-feedback">' + v.message + '</div>');
				});

				$('.wpcf7-response-output', $form).addClass('alert alert-danger');


			} else {
				$('.wpcf7-response-output', $form).addClass('alert alert-warning');
			}
		}, { passive: true }, false );
	}
	
});
